@media(max-width:1200px){
   .block_baner{
    .baner_wrap{
        height: 100%;
        padding-top: 170px;
    }
   }

}
@media(min-width:320px) and (max-width:1100px){
    .tabs{
        display: flex;
        justify-content: center;
        max-width: 100%;
        padding: 0!important;
        li{
            padding: 30px 0;
            width: 100%;
            text-align: right;
            padding-right: 15px;
            &:last-child{
                text-align: left;
                margin-right: 0;
                padding-left: 15px;
            }
        }
        .active{
            background: rgba(0, 0, 0, 0.9);
            transition: .3s linear;
        }
    }
    .tabs-panel{
        .tab_text{
            padding: 20px 10px;
        }
    }
}
@media(min-width:781px) and (max-width:950px){
    .product_wrap{
        .product_el{
            flex-basis: 47%;
        }
    }
}
@media(min-width:320px) and (max-width:860px){
    .product_wrap{
        flex-direction: column;
    }
    .block_baner{
        .baner_wrap{
            h1{
                font-size: 36px;
                letter-spacing: 0px;
            }
        } 
    }
     .tabs-panel{
         .tab_img, .tab_text{
             flex-basis: 100%;
         }
         .tab_text{
             h4{
                 padding: 10px 0 0 0;
             }
         }
     } 
    .secton_wrap{
        .section_elements{
            flex-basis: 100%;
            }
        }
    .contacts{
        .contacts_wrap{
            width: 100%;
        }
        .block_forms{
            width: 100%;
        }
        .forms, .block_adress{
            flex-basis: 100%;
        }
        .forms{
            margin-top: 20px;
            padding: 50px 30px;
        }
    }
}
@media(min-width:320px) and (max-width:780px){
    .contaner_cont{
        padding-top: 110px!important;
    }
    .prodacts_conntainer{
        flex-direction: column;
    }
    .prodacts_i{
        padding-top: 120px!important;
    }

    .dectop_menu{
        display: none;
    }
    .product_wrap{
        .product_el{
            flex-basis: 47%;
        }
    }
  .container{ 
       header.sticky{
           padding: 30px 0 30px 0;
           height: auto;
        }
        header{
            padding: 30px 0 30px 0;
        }
    }
    .mobail_menu{
        display: block!important;
        span{
            display: block;
            width: 20px;
            height: 3px;
            margin-bottom: 5px;
            background: $white;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .mobail_menu_active{
        span{
            &:nth-child(2){
                display: none;
            }
            &:first-child{
               transform: rotate(45deg);
               transform-origin: 15% 15%;
               transition: .2s ease all;
            }
            &:last-child{
               transform: rotate(135deg);
               transform-origin: 46% -16%;
               transition: .2s ease all;
            }
        }
    }
    .dectop_menu_op{
        position: relative;
        display: block!important;
        .menu{
            flex-direction: column;
            position: absolute;
            width: 300px;
           margin-top: 200px;
            padding: 50px;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            opacity: 0;
        }
    }
    .dectop_menu_active{
        .menu{
            transform-origin: 0% 0%;
            transform: translate(-65%, 0);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            background: rgba(0, 0, 0, 0.8);
            margin: 53px 0 0 -50px;
           opacity: 1;
            li{
                padding-right: 0;
                margin: 20px 0;
            }
        }
    }
    .baner2{
        .baber_wrapper{
            h3{
                font-size: 28px;
                &::after{
                    top:205px;
                }
            }
        }
    }
    .blocks_3 {
        padding-top: 126px!important;
    }
    .blocks_flex{
        flex-direction: column;
        .blocks_el:first-child{
            text-align: center;
        }
        div{
            margin-bottom: 20px;
            h3{
                text-align: center;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 2px;
                    background: -webkit-gradient(linear, right top, left top, from(#292525), to(#e6dfdf));
                    background: linear-gradient(to left, #292525, #e6dfdf);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 2px;
                    background: -webkit-gradient(linear, right top, left top, from(#e6dfdf), to(#292525));
                    background: linear-gradient(to left, #e6dfdf, #292525);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: -19px;
                }
            }
        }
    }
    .block_man_wrap{
        .wrap{
            h3{
                position: relative;
                text-align: center;
                padding-bottom: 20px;
                font-size: 24px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 2px;
                    background: -webkit-gradient(linear, right top, left top, from(#292525), to(#e6dfdf));
                    background: linear-gradient(to left, #292525, #e6dfdf);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 50px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 2px;
                    background: -webkit-gradient(linear, right top, left top, from(#e6dfdf), to(#292525));
                    background: linear-gradient(to left, #e6dfdf, #292525);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: -19px;
                }
            }
        }
    }
    .prod_cont{
        padding-top: 150px!important;
        p{
            padding-bottom: 30px!important;
        }
    }
    .baner3{
        padding: 50px 0!important;
    }
}
@media(min-width:320px) and (max-width:485px){
    .product{
        h3{
            font-size: 30px;
        }
    }
    .header_wrap{
        .header_logo{
            a{
              font-size: 20px;
            }
          }
    }
    .block_man_wrap{
        .wrap{
            h3{
                position: relative;
                text-align: center;
                padding-bottom: 20px;
                font-size: 24px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 2px;
                    background: -webkit-gradient(linear, right top, left top, from(#292525), to(#e6dfdf));
                    background: linear-gradient(to left, #292525, #e6dfdf);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 80px;
                }
               
            }
        }
    }
    .prod_cont{
        h3{
            font-size: 26px!important;
        }
    }
    .baner3{
        .baner3_wrap{
            h3{
                line-height: 46px;
                font-size: 33px;
            }
        }
    }
}