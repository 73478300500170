.sect_2 {
    padding: 100px 0 70px 0;
}
.secton_wrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.section_elements {
    flex-basis: 27%;
    margin-bottom: 40px;
    text-align: center;
    
}
.sect2_icons {
    text-align: center;
    
    svg{
        width: 60px;
        height: auto;
        fill:$col_svg;
        border: 2px solid #d3d1c863;
        padding: 10px;
    }
}
.sect2_h2 {
    text-transform: uppercase;
    color:$grey;
    padding: 30px 1px 20px;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
}
.sect2_p {
    color: $col_p;
    font-size: 17px;
    line-height: 22px;

}