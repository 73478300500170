.baner2{
    background: url(../img/parallax01.jpg)center no-repeat;
    height: auto;
}
.baber_wrapper{
    justify-content: center;
    background: rgba(0, 0, 0, .7);
    h3{
        font-size: 44px;
        font-weight: bold;
        color: $white;
        padding: 154px 0 ;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: linear-gradient(to left, #e6dfdf, #292525);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 218px;

        }
        &::before{
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: linear-gradient(to left, #292525, #e6dfdf);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 137px;

        }
    }
}