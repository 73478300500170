.block_baner{
 
    background: url(../img/page-1_img01.jpg)center/cover no-repeat;
    background-attachment: fixed;
}
.block_baner_opacyti{
    background-color: rgba(0, 0, 0, .4);

}
.baner_wrap {
    height: 100vh;
    padding-top: 230px;
    padding-bottom: 70px;
    text-align: center;
    color: $white;
    h1{
        font-size: 70px;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 20px;
    }
}

.h1_desc {
    font-size: 22px;
    color: $white;
    text-transform: uppercase;
    padding: 20px 0;
    display: block;
}
.baner_text {
    font-size: 16px;
    padding:7px 0;
}
.block_button {
   display: flex;
    justify-content: center;
}
.btn {
    display: block;
    padding: 17px 0;
    width: 230px;
    margin-top: 18px;
    margin-right: 16px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    background: rgba(255, 255, 255, .5);
    border: 1px solid rgba(255, 255, 255, .6);
    cursor: pointer;
    a{
        color: $white;
        
    }
    &:last-child{
        margin-right: 0;
    }
    &:hover{
        background: rgba(255, 255, 255, .8);
        transition: all 0.8s ease;
        color: $black;
        a{
            color:$black;
            transition: all 0.8s ease;

        }
    }

}
