.footer{
    background: rgba(0, 0, 0, 0.8);
   
    a{
        color: $col_p;
    }
    .footer_p{
        text-align: center;
        font-size: 12px;
        color: $col_p;
        padding: 40px 0;
    }
}