.prod_cont{
    padding-top: 230px;
    h3{
        text-align: center;
        font-weight: bold;
        font-size: 34px;
    text-transform: uppercase;
    max-width: 800px;
    margin: 0 auto;
    }
    p{
        text-align: center;
        color: $grey;
        line-height: 23px;
        padding: 40px 0 60px;
    }
}
.baner3{
    background: url(../img/parallax03.jpg) center/cover no-repeat;
    padding: 120px 0;
    margin: 40px 0;
}
.baner3_wrap{
    h3{
        text-align: center;
        color: $white;
        max-width: 800px;
        margin: 0 auto;
        font-weight: bold;
        line-height: 60px;
    }
}
.product_wrap{
    margin-top: 40px;
}
.product_el{
    flex-basis: 30%;
    margin-bottom: 40px;
    box-shadow: 0px 1px 11px 5px #eae9e9;
    padding-bottom: 20px;
  
}
.product{
    h3{
        font-weight: bold;
        text-align: center;
        padding: 40px 0;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: -webkit-gradient(linear, right top, left top, from(#292525), to(#e6dfdf));
            background: linear-gradient(to left, #292525, #e6dfdf);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 100px;
        
        }
        &::after {
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: -webkit-gradient(linear, right top, left top, from(#e6dfdf), to(#292525));
            background: linear-gradient(to left, #e6dfdf, #292525);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 15px;
    }}
}

// ...........
.view {
    margin: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  
  .view .mask,
  .view .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 0px 0;
  }
  
  .view img {
    display: block;
    position: relative
  }
  
 
  
  .view p {
    font-size: 16px;
    position: relative;
    color: rgb(82, 42, 42);
    padding: 20px 20px 0px;
    line-height: 20px;
    text-align: center;
  }
  
  .view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    font-family: Raleway, serif;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000;
    margin-top: 19px;
  }
  
  .view a.info:hover {
    box-shadow: 0 0 5px #000
  }
  
  .view-tenth img {
    transform: scaleY(1);
    transition: all .7s ease-in-out;
  }
  
  .view-tenth .mask {
    background-color: rgba(237, 239, 249, .8);
    transition: all 0.5s linear;
    opacity: 0;
  }
  
  .view-tenth h4 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background: transparent;
    margin: 20px 40px 0px 40px;
    transform: scale(0);
    color: $grey;
    transition: all 0.5s linear;
    opacity: 0;
    font-weight: bold;
        text-align: center;
        font-size: 22px;
        padding: 10px 0;
  }
  
  .view-tenth p {
    color: #333;
    opacity: 0;
    font-size: 14px;
    transform: scale(0);
    transition: all 0.5s linear;

  }
  
  .view-tenth a.info {
    opacity: 0;
    transform: scale(0);
    transition: all 0.5s linear;
  }
  
  .view-tenth:hover img {
    -webkit-transform: scale(10);
    transform: scale(10);
    opacity: 0;
  }
  
  .view-tenth:hover .mask {
    opacity: 1;
  }
  
  .view-tenth:hover h4,
  .view-tenth:hover p,
  .view-tenth:hover a.info {
    transform: scale(1);
    opacity: 1;
  }