.prodacts_i{
    padding-top: 250px;
    padding-bottom: 40px;
}
.prodacts_img, .prodacts_contant{
    flex-basis: 50%;
    box-sizing: border-box;
}
.prod_list{
    margin-top: 30px;
    padding:0 15px;
    ul{
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 15px;
        li{
            color: #9b9b9b;
    padding-bottom: 15px;
    padding-left: 27px;
    background: url(../img/correct-3.png) no-repeat scroll 2px 0px;
        }
    }
}
.prodacts_contant{
    h3{
        font-weight: bold;
        padding: 20px 15px;
    }
    p{
        color: #9b9b9b;
        padding: 0  15px 10px;
    }
    span{
        font-weight: bold;
        display: block;
        background: rgba($color: #000000, $alpha: .8);
        padding: 13px 8px;
        color: $white;
    }
}