@charset "UTF-8";
@import "reset";
@import "variables";
@import "fonts";
@import "mixins";
@import "core";
@import "blocks/media";
@import "blocks/baner";
@import "blocks/sect2";
@import "blocks/tabs";
@import "blocks/baner2";
@import "blocks/contacts";
@import "blocks/footer";
@import "about/about.scss";
@import "prod/prod.scss";
@import "prod/products.scss";
@import "contact/contacts.scss";
// === header =====
.header_container{
    background: url(../img/bg-head.jpg)center/cover no-repeat;
    height: 164px;
    background: none;
    position: absolute;
    z-index: 1;
}
header{
    position: fixed;
    width: 100%;
    background: rgba($color: #000000, $alpha: .8);
    padding: 60px 0;
    transition: all 0.4s ease;
    }
header.sticky {
    font-size: 16px;
    height: 48px;
    background: rgba($color: #000000, $alpha: .8);
    padding: 30px 0 10px 0;
    transition: all 0.4s ease;
}
.header_wrap{
    align-items: center;
}
.header_logo{
    a{
        color:$white;
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
        letter-spacing: 0.08em;
        text-transform: uppercase;
    }
}
.logo_border{
    border: 3px solid $white;
    padding: 3px 6px;
}
.menu{
    align-items: center;
}
.menu_item{
    color:$cm;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    font-family: Noto-sans;
   padding-right: 25px;
   position: relative;

   &:last-child{
       padding-right: 0;
   }
   
}
.menu_item_active{
    color: $m_ac;
    position: relative;
   a{
       position: relative;
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background:  $m_ac;
        display: block;
        bottom: -7px;
    }
   }

}
// ============ scrool
.back_to_top {
    position: fixed;
    bottom: 53px;
    right: 40px;
    z-index: 9999;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 35px;
    font-size: 23px;
    background: #249024;
    color: #f5f3f3;
    cursor: pointer;
    border-radius: 2px;
    display: none;
    font-weight: bold;
    border-radius: 50%;
  }
  
  .back_to_top:hover {
    background: #156615;
  }
  
  .back_to_top-show {
    display: block;
  }