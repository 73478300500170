.contacts{
    margin: 80px 0;
}
.contacts_wrap{
    width: 600px;
    margin: 80px auto 40px;
    text-align: center;
    h3{
        text-transform: uppercase;
        color: $grey;
        font-weight: bold;
        position: relative;
        padding-bottom: 40px;
        &::after{
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: linear-gradient(to left, #e6dfdf, #292525);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -9px;

        }
        &::before{
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: linear-gradient(to left, #292525, #e6dfdf);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50px;

        }
    }
    p{
        color:$col_p;
        padding-bottom: 10px;
    }
    h4{
        font-weight: bold;
        padding: 10px 0;
    }
}
.block_forms{
    justify-content: space-around;
    width: 800px;
    margin: 0 auto;
}
.forms{
    display: flex;
    flex-direction: column;
    flex-basis: 44%;
    padding: 50px 40px;
   
    input{
        outline: none;
        background: none;
        margin-bottom: 10px;
        border: 1px solid $cm;
        padding: 7px 10px;
    }
    textarea{
        outline: none;
        background: none;
        border: 1px solid $cm;
        padding-top: 7px;
        padding-left: 10px;
        color: $grey;
        resize: none;

    }
}
.block_adress{
    flex-basis: 32%;
    padding: 50px 40px;
    h4{
        color:$m_ac;
        font-weight: bold;
    }
    p{
        color: $col_p;
        padding: 20px 0;
        line-height: 20px;
    }
    a{
        color: $col_p;

    }
}
.block_adress, .forms{
    background: #f9f8f6e3;
    box-shadow: 0px 0px 6px 2px #d3d1c8
}
.form_btn{
    display: block;
    width: 150px;
    padding: 10px 0;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 20px;
    color: $white;
    font-weight: bold;
    background: $m_ac;
    border: 1px solid $m_ac;
    box-shadow: 1px 1px 4px 1px #9b9b9b;
}