.blocks_el{
    flex-basis: 30%;
    h3{
        font-size: 32px;
        font-weight: bold;
        padding-bottom: 40px;
    }
    h4{
        font-weight: bold;
    }
    img{
        padding: 0 0 5px 0;
    }
    p{
        color: $grey;
        padding: 0 0 10px 0;
        font-size: 14px;
        line-height: 22px;
    }
    ul{
        li{
            color: $col_p;
            padding-bottom: 15px;
            padding-left: 27px;
            background: url(../img/correct-3.png) no-repeat scroll 2px 0px;
        }
    }
}
.blocks_3{
    padding-top: 300px;
}
.block_man{
    margin: 40px 0;
    padding: 70px 0;
    background: url(../img/parallax02.jpg)center/cover no-repeat;

}
.block_man_wrap{
    .wrap{
       h3{
           color: $white;
        font-size: 34px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 7px;
       }
       p{
           color: $white;
           padding: 5px 0px 10px 0;
           line-height: 20px;
       }
       h4{
        color: $white;
        font-size: 24px;
        padding: 20px 0  10px 0;
       }
    }
}
.work{
    padding: 40px 0;
}
.wrap_work{
    text-align: center;
    h3{
        text-align: center;
        position: relative;
        font-weight: bold;
        &::before {
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: -webkit-gradient(linear, right top, left top, from(#292525), to(#e6dfdf));
            background: linear-gradient(to left, #292525, #e6dfdf);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 151px;
            height: 2px;
            background: -webkit-gradient(linear, right top, left top, from(#e6dfdf), to(#292525));
            background: linear-gradient(to left, #e6dfdf, #292525);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -19px;
        }
    }
}
.wrap_work_flex{
    margin-top: 40px;
}
.work_el{
    margin: 0 auto;
    h4{
        padding-bottom: 20px;
        font-weight: bold;
    }
    p{
        padding-bottom: 30px;
        max-width: 600px;
        margin: 0 auto;
        color: $grey;
        line-height: 18px;
    }
}