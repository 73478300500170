.tabs-container{
    background: rgba(0, 0, 0, .8);
   
}
.tabs {
        display: flex;
        align-items: center;
        padding: 30px 15px;
    li{
        margin-right: 20px;
        font-size: 16px;
        a{
            color: $cm;
            text-decoration: none;
        }
    }
    .active{
       a{
        color: $white;
       }
    }
}
  .tabs-panel {
    display: none;
  }
  
  .tabs-panel.active {
    display: flex;
   
  }
  .tab_img{
      flex-basis: 40%;
      img{
          float: right;
      }
  }
  .tab_text{
      flex-basis: 60%;
      padding: 94px 100px;
      box-sizing: border-box;
      h3{
          font-size: 36px;
          font-weight: bold;
          color:$black;
          line-height: 40px;
      }
      p{
        color: $col_p;
        line-height: 24px;
        padding: 15px 0 0 0;

      }
      h4{
        font-size: 22px;
        font-weight: normal;
        color:$black;
      }
  }