body{
  font-family: 'Roboto','Arial',sans-serif;
  font-size: 16px;
  background: #fefff0;
}
.container{
  @include min-max(320px,100%,0)
}
.wrap{
  @include min-max(320px,1170px,15px)
}
.images{
  display: block;
  max-width: 100%;
}
h1{
  font-size: 78px;
}
h3{
  font-size: 44px;
}
h4{
  font-size: 22px;
}
.flex{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap ;
  box-sizing: border-box;
}
a{
  cursor: pointer;
  color: $cm;
}
